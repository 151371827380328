import React, { PropsWithChildren } from 'react'
import type { SitewideBanner } from '@/types/Contentful/SitewideBanner'
import type { MetaProps } from '@/components/Sitewide/Meta'
import type { GraphQlCustomer } from '@/helpers/graphql'
import type { IMeganav } from '@/types/Meganav'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import dynamic from 'next/dynamic'

import { loadStripe } from '@stripe/stripe-js'
import { CSC_PAYMENTS_PK } from '@/services/Configuration'
import type { EventBannerType } from '@/types/Contentful/EventBanner'
import type { FooterDeploymentType } from '@/types/Contentful/Footer'
import { useIsEventBannerVisible } from '@/hooks/useIsEventBannerVisible'

const PageContent = dynamic(import('@csc/dls/PageContent'))
const Elements = dynamic(import('@stripe/react-stripe-js').then((mod) => mod.Elements))
const Theme = dynamic(import('@csc/dls/Theme'))
const ScrollTopButton = dynamic(import('@csc/dls/ScrollTopButton'))
const Head = dynamic(import('next/head'))

const Banner = dynamic(import('@/components/Sitewide/Banner'))
const EventBanner = dynamic(import('@/components/Sitewide/EventBanner'))
const Footer = dynamic(import('@/components/Sitewide/Footer'))
const Navbar = dynamic(import('@/components/Sitewide/Navbar'))
const Meta = dynamic(import('@/components/Sitewide/Meta'))
const UserLoginSignUpWithContext = dynamic(import('@/components/UserLoginSignUpWithContext'))
export interface DefaultLayoutProps {
  meta: MetaProps,
  pageType?: string,
  cart: StandardCart | null,
  onUpdateCart?: React.Dispatch<React.SetStateAction<StandardCart | null>>
  user: GraphQlCustomer | null
  sitewideBanner: SitewideBanner | null
  megaNav: IMeganav | null,
  PageContentDiv?: React.FC<PropsWithChildren>
  eventBanner?: EventBannerType | null
  maybeFooterFromContentful?: FooterDeploymentType | null
  reloadCustomer: () => void
}

const stripePromise = loadStripe(CSC_PAYMENTS_PK)

const DefaultLayout: React.FC<PropsWithChildren<DefaultLayoutProps>> = ({
  children,
  meta,
  pageType = '',
  cart,
  onUpdateCart = () => { },
  sitewideBanner,
  megaNav,
  user,
  PageContentDiv = PageContent,
  eventBanner,
  maybeFooterFromContentful,
  reloadCustomer,
}) => {
  const isEventBannerVisible = useIsEventBannerVisible(eventBanner)
  return (
    <Elements stripe={stripePromise}>
      <Theme
        themeId="okl"
        ScriptContainer={Head}
        typographyComponents={{
          'countdown-clock-value': {
            fontFamily: 'var(--font-body)',
            fontSize: 'md',
            lineHeight: '100%',
            letterSpacing: '0.02em',
            fontWeight: 800,
            color: 'var(--color-primary)',
            textTransform: 'uppercase',
          },
        }}
      >
        <UserLoginSignUpWithContext reloadCustomer={reloadCustomer}>
          <Meta title={meta.title} description={meta.description} />
          {pageType !== 'cart' && !!sitewideBanner && (
            <Banner
              messaging={sitewideBanner.messaging}
              callToActionText={sitewideBanner.callToActionText}
              callToActionUrl={sitewideBanner.callToActionUrl}
              countdownTarget={sitewideBanner.countdownTarget}
              tiles={sitewideBanner.tiles}
            />
          )}
          { !isEventBannerVisible ? null : <EventBanner eventBanner={eventBanner} /> }
          {!!megaNav && (
            <Navbar
              user={user}
              cart={cart}
              megaNav={megaNav}
              onUpdateCart={onUpdateCart}
              pageType={pageType}
              isEventBannerVisible={isEventBannerVisible}
            />
          )}
          <PageContentDiv>
            {children}
          </PageContentDiv>
          <Footer maybeFooterFromContentful={maybeFooterFromContentful} />
          <ScrollTopButton
            style={{
              right: 'calc( min(2vh, 2vw) + 6px )',
              bottom: '90px',
            }}
          />
        </UserLoginSignUpWithContext>
      </Theme>
    </Elements>
  )
}

export default DefaultLayout
